<template>
    <div>
        <div v-for="category in getCategories" :key="category">
            <div v-if="categoryView" class="header mt-2">
                <h3 class="title is-5 mb-1 has-text-weight-bold"> {{ category }} </h3>
            </div>
            <div class="">
                <ul class="menu-list columns is-multiline" style="">
                    <li v-for="product in products.filter((pro) => pro.category == category).sort((a,b)=> a.product.localeCompare(b.product))" :key="product._id" class="column is-half-tablet is-one-third-desktop">
                        <a class="columns is-mobile mb-0 my-0 py-0" :style="product.visible ? '' : 'opacity:0.7'">
                            <span @click="redirectTo(product)" class="column is-4 p-0"> 
                                <b-image v-if="product.image" ratio="4by3" loading="lazy" :src="product.image" :alt="product.image"></b-image>
                                <!-- <b-image ratio="4by3" :src="require(product.image)"></b-image> -->
                                <!-- <b-image ratio="4by3" :src="getImage(product.image)"></b-image> -->
                                <!-- <b-image ratio="4by3" :src="require('@/assets/img/'+product.image)"></b-image> -->
                                <!-- :src="`${post.image}`" :alt="`${post.product}`" -->
                            </span>
                            <span :class="`column p-0 ${alreadyInCart(product._id) ? 'inCart' : ''}`"> 
                                <div class="pl-3">
                                    <p @click="redirectTo(product)" class="title mb-1 is-6 has-text-weight-semibold" style="font-weight: 300"> 
                                        
                                         {{ product.product }} 
                                    </p>
                                    
                                    <p  @click="redirectTo(product)" class=" is-6 mb-3">{{ (product.available ? product.details : 'Out of stock') }} </p>
                                    <span  @click="redirectTo(product)" v-if="product.discount !== 0" class="mr-1"><span class="mr-1" style="text-decoration:line-through"> GH₵{{ formatPrice(product.price) }} </span> <b-tag type="is-primary has-text-weight-semibold " rounded> GH₵{{ formatPrice( getDiscount(product.price, product.discount) ) }} </b-tag> </span>
                                    <span  @click="redirectTo(product)" v-else>GH₵{{ formatPrice(product.price) }}</span>
                                    
                                    <div class="columns is-mobile mt-2 pl-3">
                                        <b-switch @input="visible(product)" size="is-small" v-model="product.visible"> 
                                            Visible
                                        </b-switch>
                                        <b-switch @input="inStock(product)" size="is-small" v-model="product.available">
                                            In Stock
                                        </b-switch>
                                    </div>
                                </div>
                            </span>
                            <span class="column is-2 p-0"> 
                                <div class="is-flex is-flex-direction-column is-align-items-center">
                                    <div class="column py-1 px-0">
                                        <b-button size="is-small" @click="edit(product)" rounded icon-left="pencil"></b-button>
                                    </div>
                                    <!-- <div class="column py-1 px-0">
                                        <b-button size="is-small" @click="addQty(product)" rounded icon-left="delete"></b-button>
                                    </div> -->
                                </div>
                                
                            </span>
                        </a>
                        <!-- <hr class="m-0 p-0 mb-2"> -->
                    </li>
                </ul>
            </div>
        </div>
            
    </div>
</template>

<script>

    import { mapGetters } from "vuex"

    export default {
        name: 'ProductTemplate',
        data() {
            return {
                isCardModalActive: false,
                currentProduct: {},
                screenWidth: 0
            }
        },
        props: {
            products: {
                required: true
            },
            categoryView: {
                type: Boolean,
                default: true
            },
            edit: {
                type: Function
            }
        },
        mounted() {
            this.updateScreenWidth();
            this.onScreenResize();
        },
        computed: {
            ...mapGetters('cart', ['totalPrice']),
            ...mapGetters('cart', ['cartCount']),
            ...mapGetters('cart', ['getCartBusiness']),

            getCategories() {

                const allcategories =  this.products.map(product => product.category)
                const categories  = [...new Set(allcategories)].sort()
                return categories
            },
            
        },
        methods: {
            
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            formatPrice(price) {
                return price.toFixed(2)
            },
            getDiscount(price, disc) {
                return ((100 - disc)/100) * price;
            },
            loadProduct(product){
                this.currentProduct = product
                //open
                this.isCardModalActive = true
            },
            getImage(img) {
                const fullPath = '../assets/img/'+img
                console.log(fullPath)
                return fullPath
            },
            redirectTo (currentProduct) {
                // if (!currentProduct.available){
                //     return
                // }

                this.$router.push({ name: 'product', 
                                    params: { 
                                        id: currentProduct._id,
                                        available: currentProduct.available,
                                        business: currentProduct.business, 
                                        image: currentProduct.image,
                                        product: currentProduct.product, 
                                        discount: currentProduct.discount,
                                        tags: currentProduct.tags,
                                        price: currentProduct.price,
                                        type: currentProduct.type,
                                        details: currentProduct.details,
                                        description: currentProduct.description, 
                                        visible: currentProduct.visible, 
                                    } 
                                })
            },
            alreadyInCart(id) {
                // console.log(this.$store)
                return this.$store.getters['cart/alreadyInCart'](id)
            },
            inCart(id) {
                // console.log(this.$store)
                const cart = this.$store.getters['cart/inCart'](id)
                return cart
            },
            visible(product) {
                //
                this.$buefy.toast.open(`${product.product} is ${product.visible ? 'visible to public' : 'not visible to public'}`)

                if (product.visible) {
                    this.$store.dispatch('products/productVisible', { product: product.product, business: product.business })
                } else {
                    this.$store.dispatch('products/productInvisible',  { product: product.product, business: product.business } )
                }
            }, 
            inStock(product) {
                //
               this.$buefy.toast.open(`${product.product} is set to ${product.available ? 'In Stock' : 'Out of Stock'}`)

               if (product.available) {
                    this.$store.dispatch('products/productAvailable',  { product: product.product, business: product.business } )
                } else {
                    this.$store.dispatch('products/productUnavailable',  { product: product.product, business: product.business } )
                }
            },
            getQty(product) {
                return product.qty
            }
        } 
    }
</script>

<style lang="scss" >// custom
    @import '@/assets/scss/custom_variables.scss';
    
    .modal-close {
        background: gray !important;
    }

    // .inCart {
        // border-left: 4px solid $primary;
    // }

    //  .modal-content {
    //     height: 90% !important;
    //     border-radius: 20px;
    //     margin-top: 20% !important;
    // }

    .order-cover {
        position: fixed;
        z-index: 50;
        width: calc(100% - 50px);
        // left: 0;
        // padding-bottom: 20px;
        margin-bottom: 5px;
        bottom: 10px;
    }
</style>