import gql from "graphql-tag";

export const GET_ORDER_QUERY = gql`
  query allOrders($orderNo: String, $status: String, $type: String, $paymentType: String, $delivery: String, $vendor: String, $courier: String, $user: JSON ) {
    allOrders(orderNo: $orderNo, status: $status, type: $type, paymentType: $paymentType, delivery: $delivery, vendor: $vendor, courier: $courier, user: $user) {
        _id
        orderNo
        user
        status
        confirmed
        courierAssigned
        courierDelivering
        orderDelivered
        cancelled
        type
        paymentType
        payment
        totalActualPrice
        totalPrice
        deliveryFee
        total
        deliveryDate
        deliveryAddress
        deliveryAddressName
        deliveryNote
        vendor
        coverImage
        data
        udata
        username
        items
        pickupRequest
        courier
        createdAt
    }
  }` 

export const GET_BUSINESS_ORDER_QUERY = gql`
  query order($orderNo: String!) {
    order(orderNo: $orderNo) {
      _id
      orderNo
      user
      status
      confirmed
      courierAssigned
      courierDelivering
      orderDelivered
      cancelled
      type
      paymentType
      payment
      totalActualPrice
      totalPrice
      deliveryFee
      total
      deliveryDate
      deliveryAddress
      deliveryAddressName
      deliveryNote
      vendor
      coverImage
      data
      udata
      username
      items
      pickupRequest
      courier
      createdAt
    }
  }
`
