<template>
    <div>
        
        <b-loading v-if="isLoading" :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
        <!-- <div class="sidebar-wrapper"> -->
            
            <SideNav :cusheader="onlineStatus" :isSwitch="true" :switchValue="getUser.online" :Switched="isOnline" />
            
        <!-- </div> -->
        <div class="p-1">
            <GoogleMaps :marker2="marker2" :marker2Cords="VendorLocation" :height="'calc(99vh - 127px)'"/>
        </div>
        <MainFooter
            v-if="data"
            :status="pickupOrderStatus"
            :title="vendor"
            :subtitle="location"
            :text="`Pickup ${pickupTime ? 'in '+pickupTime : ''}`"
            :action="pickupOrder"
        />
        <div v-else class="p-3 text-center">
            <div  class="px-5">
                <div class="p-2">
                    <h4 class="title is-5">Waiting for Orders</h4>
                    <p class="subtitle">Keep screen active</p>
                </div>
            </div>
        </div>
        <MainFooter
            v-if="order"
            :status="orderStatus"
            :title="vendor"
            :subtitle="location"
            :text="`Check Order ${order.status ? '- '+order.status[0].toUpperCase()+order.status.substr(1) : ''}`"
            :action="modalActive"
        />
        

        <b-modal v-if="isCardModalActive"  v-model="isCardModalActive" >
            <div class="p-4 has-bg-white " style="">
                <div class="p-2 card">
                    <div class="p-4 columns is-mobile mb-1">
                        <div class="column is-10">
                        <h3 class="title is-5"> {{ vendor }}</h3>
                            <p class="subtitle">{{ location }}</p>
                            <b-tag class="" rounded :type="`${order.status == 'completed' ? 'is-primary' : order.status == 'delivering' ? 'is-link' : 'is-warning'} has-text-weight-semibold`"> {{ order.status }} </b-tag>
                            <span class="mr-1 has-text-grey small"> {{ order.courierAssigned ? formatAMPM(order.courierAssigned) : order.courierDelivering ? formatAMPM(order.courierDelivering) : order.orderDelivered ? formatAMPM(order.orderDelivered) : formatAMPM(order.createdAt) }} </span>
                        </div>
                        <div class="column is-flex is-justify-content-right mt-2">
                            <a :href="`tel:${order.data.phone.countryCode+order.data.phone.tel}`" class="has-text-primary">
                                   <feather type="phone"></feather>
                            </a>
                        </div>
                    </div>
                    <hr class="m-1">
                    <div class="p-4">
                        <div class="px-5 py-3">
                            <h6 class="title is-size-6 mb-2">Delivery Fee</h6>
                            <p  class=" is-5 mb-1 has-text-weight-medium is-flex is-justify-content-left is-align-items-center"> GHC {{ formatPrice(order.deliveryFee) }}  </p>
                            
                        </div>
                        <div class="notification pb-3">
                            <h6 class="title is-size-6">Delivery Note</h6>
                            <p class="subtitle">{{ order.deliveryNote ? order.deliveryNote : 'No delivery note' }}</p>
                        </div>
                    </div>
                
                    <div v-if="order.status == 'assigned'" class="p-4">
                        <b-button @click="deliverOrder" expanded rounded  type="is-primary"> Start Delivery </b-button>
                    </div>
                    <div v-if="order.status == 'delivering'" class="p-4">
                        <div class="columns is-mobile">
                            <div class="column">
                                <b-button @click="deliveredOrder" expanded rounded  type="is-primary">Delivered </b-button>
                            </div>
                            <div class="column">
                                <b-button tag="a" :href="`tel:${order.udata.phone.countryCode+order.udata.phone.tel}`" expanded rounded outlined type="is-primary">Call {{ order.udata.name.split(' ')[0] }} </b-button>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            
        </b-modal>

    </div>
</template>

<script>

    import { mapGetters, mapActions} from 'vuex'
    import axios from 'axios'
    
    // import gql from 'graphql-tag'
    import SideNav from '../components/sidenav'
    import VendorSkeleton from '../components/VendorSkeleton'
    import OrderTemplate from '../components/OrderTemplate'
    
    import MainFooter from '../components/footer'

    import GoogleMaps from '../components/GoogleMaps'

    export default {
        name: 'IndexPage',
        data(){
            return {
                isLoading: false,
                screenWidth: 0,
                isCardModalActive: false,
                onlineStatus: '',
                lastPath: null,
                pickupOrderStatus: false,
                orderStatus: false,
                vendor: '',
                location: '',
                order: null,
                data: null,
                marker2: false,
                VendorLocation: null,
                pickupTime: null,
                deliveryTime: null,
                // getUser: {
                //     firstname: 'Joe',
                //     lastname: 'Tag',
                // }
            }
        },
        components: {
            SideNav,
            GoogleMaps,
            // VendorSkeleton,
            // OrderTemplate,
            MainFooter
        },
        created() {
            // watch and fetch
            this.$watch(
            () => this.$route.$params,
            async () => {
                // this.$store.dispatch('vendors/getBusiness')
                
                // mapActions('vendors',['getBusiness'])
                this.getAllOrders()
            },
            { immediate: true }

            )

        },
        watch: {
            pickupOrderStatus() {
                if (this.pickupOrderStatus) {
                    this.onlineStatus = 'Online'
                } else {
                    this.onlineStatus = 'You\'re offline'
                }
            },
            assigned(){
                if (this.assigned.length > 0) {
                    this.order = this.assigned[0],
                    this.vendor = this.assigned[0].vendor
                    this.location = this.assigned[0].data.locationName
                    this.orderStatus = true
                }
            },
            delivering(){
                if (this.delivering.length > 0) {
                    this.order = this.delivering[0],
                    this.vendor = this.delivering[0].vendor
                    this.location = this.delivering[0].data.locationName
                    this.orderStatus = true
                }
            },
        },
        mounted(){
            this.updateScreenWidth();
            this.onScreenResize();
            this.CheckPickup();
            this.initCheckPickup()
            this.checkOnlineStatus()
        },
        computed: {
            // ...mapGetters('cart', ['totalPrice']),
            // ...mapGetters('cart', ['cartCount']),
            // ...mapGetters('cart', ['getCartBusiness']),
            ...mapGetters('auth', ['getUser']),
            ...mapGetters('auth', ['address']),
            ...mapGetters('orders', ['getOrders']),
            ...mapGetters('orders', ['assigned']),
            ...mapGetters('orders', ['delivering']),
            ...mapGetters('orders', ['delivered']),
            // ...mapGetters('orders', ['confirmed']),
            // ...mapGetters('orders', ['completed']),
        },
        methods: {
            modalActive(){
                this.isCardModalActive = true
            },
            formatPrice(price) {
                return price.toFixed(2)
            },
            formatAMPM(date) {
                date =  date ? new Date(date) : new Date()
                var hours = date.getHours();
                var minutes = date.getMinutes();
                var ampm = hours >= 12 ? 'pm' : 'am';
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                minutes = minutes < 10 ? '0'+minutes : minutes;
                var strTime = hours + ':' + minutes + ' ' + ampm;
                return date.toDateString()+', '+strTime;
            },
            getDistanceTime(type) {
                //
                const order =  this.data
                //
                axios.post('/order/distance', { origin: [this.address.location.lat, this.address.location.lng], destination: order.location.coordinates })
                    .then( response => {

                        const res = response.data
                        // time manipulation + 5 incase of delays
                        const { time, distance } = res
                        //
                        this.pickupTime = time
                })
            },
            checkOnlineStatus() {
                //
                if (this.getUser.online) {
                    this.onlineStatus = 'Online'
                } else {
                    this.onlineStatus = 'You\'re offline'
                }
            },
            getImgUrl(value) {
                return `/assets/img/slider/${value}`
            },
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            CheckPickup() {
                //
                axios.get('/order/courier/pickup?courier='+this.getUser.firstname+' '+this.getUser.lastname).then(({ data })=>{
                    //
                    console.log(data)

                    if (data){
                        //
                        this.vendor = data.vendor
                        this.location = data.vendorLocation
                        this.data = data
                        this.pickupOrderStatus = true
                        this.VendorLocation = { lat: data.location.coordinates[0], lng: data.location.coordinates[1] }
                        this.marker2 = true
                        //
                        this.getDistanceTime(0)
                    }
                    
                }).catch((err)=>{
                    console.log(err)
                })
            },
            initCheckPickup() {
                //
                if (this.assigned.length > 0 || this.delivering.length > 0 ) {
                    //
                    // this.order = this.assigned[0]

                    return
                }
                //
                const timer = setInterval(()=>{
                    //
                    this.CheckPickup()

                    if (this.$route.name !== 'index') {
                        // final step
                        clearInterval(timer)
                    }

                    if (this.orderAssigned) {
                        // final step
                        clearInterval(timer)
                    }

                    if (this.order) {
                        // final step
                        clearInterval(timer)
                    }

                    if (this.data) {
                        // final step
                        clearInterval(timer)
                    }

                }, 10*1000)

                // console.log(new Date)
            },
            pickupOrder(){
                this.isLoading = true
                const order = this.data
                

                if (order){
                    // axios
                    this.$axios.post('/order/status/courier', { action: 'assigned', orderNo: order.orderNo, courier: this.getUser.firstname+' '+this.getUser.lastname }).then(res => {
                            //
                            this.$buefy.toast.open(res.data.message)

                            //
                            if (res.data.data) {
                                //
                                this.isCardModalActive =true
                                this.isLoading =false
                                this.data = res.data.data
                                this.response = res.data.message

                                this.getAllOrders()
                            }

                        }).catch(error=> {
                            console.log(error)
                        })

                }
            },
            deliverOrder(){
                this.isLoading = true
                const order = this.order
                

                if (order){
                    // axios
                    this.$axios.post('/order/status/courier', { action: 'delivering', orderNo: order.orderNo, courier: this.getUser.firstname+' '+this.getUser.lastname }).then(res => {
                            //
                            this.$buefy.toast.open(res.data.message)

                            //
                            if (res.data.data) {
                                //
                                this.isCardModalActive =true
                                this.data = res.data.data
                                this.response = res.data.message
                                this.isLoading = false

                                this.getAllOrders()
                            }

                        }).catch(error=> {
                            console.log(error)
                        })

                }
            },
            deliveredOrder(){
                this.isLoading = true
                const order = this.order
                

                if (order){
                    // axios
                    this.$axios.post('/order/status/courier', { action: 'delivered', orderNo: order.orderNo, courier: this.getUser.firstname+' '+this.getUser.lastname }).then(res => {
                            //
                            this.$buefy.toast.open(res.data.message)

                            //
                            if (res.data.data) {
                                //
                                this.isCardModalActive = false
                                this.orderStatus = false
                                this.isLoading = true
                                this.data = null
                                this.order = null
                                this.vendor = null
                                this.location = null

                                this.getAllOrders()
                            }

                        }).catch(error=> {
                            console.log(error)
                        })

                }
            },
            CancelTemplate(order){
                if (order){
                    this.$buefy.dialog.confirm({
                        title: 'Cancel Order',
                        message: `Are you sure you want to cancel order?`,
                        confirmText: 'Yes',
                        onConfirm: () => {
                            // axios
                            this.$axios.post('/order/status/vendor', { action: 'cancelled', orderNo: order.orderNo, vendor: order.vendor }).then(res => {
                                //
                                this.$buefy.toast.open('Successful, Order is cancelled')
                                //
                                this.$store.dispatch('orders/getOrders')

                            }).catch(error=> {
                                console.log(error)
                            })
                        }
                    });
                }
            },
            isOnline(event) {
                // console.log(event)

                if (event) {
                    this.onlineStatus = 'Online'
                } else {
                    this.onlineStatus = 'You\'re offline'
                }

                //
                axios.post('/courier/online', { firstname: this.getUser.firstname, lastname: this.getUser.lastname, status: event } ).then(res=>{
                    //
                    this.$buefy.toast.open(res.data+', '+this.onlineStatus)
                    //
                    console.log(res.data) 
                })
            },
            async getAllOrders() {

                await this.$store.dispatch('orders/getOrders')

                // const resp = await this.$apollo.provider.defaultClient.query({ query: GET_LOCATION_BUSINESS_QUERY, variables: { location: [5.6305863,-0.1474174]} });
                
                // const resp = await this.$apollo.provider.defaultClient.query({

                //                 query: gql`query businesses {
                //                             businesses {
                //                                     _id
                //                                     business
                //                                     type
                //                                     coverImage
                //                                     location
                //                                     country
                //                                     freeDelivery
                //                                     deliveryFee
                //                                     deliveryTime
                //                                     menuDiscount
                //                                     ratings
                //                                 }
                //                             }
                //                             `
                //             });

                // (function () {
                //     const data = resp.data.businesses
                //     for (let index = 0; index < data.length; index++) {
                //         // const element = array[index];
                //         const rateLen = data[index].ratings.length

                //         if (rateLen > 0) {
                //         // sum ratings
                //         const rate = (data[index].ratings.reduce((a, b) => a + b, 0)) / rateLen
                //         // to 2 decimalplaces
                //         data[index].rate = parseFloat(rate.toFixed(1))

                //         // data.push(data[index])
                //         }
                //     }
                // })()

                // this.vendors = resp.data.businesses
            },
            checkOrderStatus(time) {

                if (this.$route.name !== 'index') {
                    return
                }

                //
                const filtered =  this.getOrders.find(order=> ["assigned", "delivering", "delivered"].includes(order.status))

                switch (filtered.status) {
                    case "assigned":

                        this.order = filtered

                        break;

                    case "delivering":
                        this.assigned.icon = 'check'

                        break;

                    case "delivered":
                        this.isLoading = false

                        this.isModalActive = true

                        break;
                
                    default:
                        break;
                    }
            },
        }
    }
</script>

<style lang="scss" >
// custom
@import '@/assets/scss/custom_variables.scss';

    .tabs li.is-active a {
        border-bottom-color: $primary !important;
        color: $primary !important;
    }
    .white {
        color: $white !important;
    }

    .carousel-items {
        border-radius: 1rem;
    }
    .slider-image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
    }

    .space {
        padding: 5px;
    }

    .cov {
        overflow-x: scroll;
        -ms-overflow-style: none;
        -moz-overflow-style: none;
        scrollbar-width: none;
    }

    .cov::-webkit-scrollbar {
    display: none;
    }

    .cov .media-content {
        overflow: hidden;
    }

    .card-content  {
        padding: 1rem 1.25rem  !important;
    }

    .b-tabs .tab-content {
        padding: 3px !important;
    }

    .media-content .b-skeleton {
        margin:  0 !important;
    }
</style>