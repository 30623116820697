<template>
  <div class="columns is-mobile cov">
    <div class="column is-two-thirds-mobile is-half-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd">
      <div class="card" style="">
        <div class="card-image">
          <div class="b-skeleton is-animated">
            <div class="b-skeleton-item  image is-3by1" style="border-top-right-radius: 1rem;border-top-left-radius: 1rem;padding-bottom:0;" />
          </div>
        </div>
        <div class="card-content">
          <div class="media">
            <div class="media-content">
              <div class="media-content">
                <b-skeleton width="120"  size="is-small" />
                <b-skeleton width="80" size="is-small"  />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-two-thirds-mobile is-half-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd ">
      <div class="card" style="">
        <div class="card-image">
          <div class="b-skeleton is-animated">
            <div class="b-skeleton-item  image is-3by1" style="border-top-right-radius: 1rem;border-top-left-radius: 1rem;padding-bottom:0;" />
          </div>
        </div>
        <div class="card-content">
          <div class="media">
            <div class="media-content">
                <b-skeleton width="120"  size="is-small" />
                <b-skeleton width="80" size="is-small"  />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-two-thirds-mobile is-half-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd ">
      <div class="card" style="">
        <div class="card-image">
          <div class="b-skeleton is-animated">
            <div class="b-skeleton-item  image is-3by1" style="border-top-right-radius: 1rem;border-top-left-radius: 1rem;padding-bottom:0;" />
          </div>
        </div>
        <div class="card-content">
          <div class="media">
            <div class="media-content">
                <b-skeleton width="120"  size="is-small" />
                <b-skeleton width="80" size="is-small"  />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-two-thirds-mobile is-half-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd ">
      <div class="card" style="">
        <div class="card-image">
          <div class="b-skeleton is-animated">
            <div class="b-skeleton-item  image is-3by1" style="border-top-right-radius: 1rem;border-top-left-radius: 1rem;padding-bottom:0;" />
          </div>
        </div>
        <div class="card-content">
          <div class="media">
            <div class="media-content">
                <b-skeleton width="120"  size="is-small" />
                <b-skeleton width="80" size="is-small"  />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-two-thirds-mobile is-half-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd ">
      <div class="card" style="">
        <div class="card-image">
          <div class="b-skeleton is-animated">
            <div class="b-skeleton-item  image is-3by1" style="border-top-right-radius: 1rem;border-top-left-radius: 1rem;padding-bottom:0;" />
          </div>
        </div>
        <div class="card-content">
          <div class="media">
            <div class="media-content">
                <b-skeleton width="120"  size="is-small" />
                <b-skeleton width="80" size="is-small"  />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-two-thirds-mobile is-half-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd ">
      <div class="card" style="">
        <div class="card-image">
          <div class="b-skeleton is-animated">
            <div class="b-skeleton-item  image is-3by1" style="border-top-right-radius: 1rem;border-top-left-radius: 1rem;padding-bottom:0;" />
          </div>
        </div>
        <div class="card-content">
          <div class="media">
            <div class="media-content">
                <b-skeleton width="120"  size="is-small" />
                <b-skeleton width="80" size="is-small"  />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-two-thirds-mobile is-half-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd ">
      <div class="card" style="">
        <div class="card-image">
          <div class="b-skeleton is-animated">
            <div class="b-skeleton-item  image is-3by1" style="border-top-right-radius: 1rem;border-top-left-radius: 1rem;padding-bottom:0;" />
          </div>
        </div>
        <div class="card-content">
          <div class="media">
            <div class="media-content">
                <b-skeleton width="120"  size="is-small" />
                <b-skeleton width="80" size="is-small"  />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-two-thirds-mobile is-half-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd ">
      <div class="card" style="">
        <div class="card-image">
          <div class="b-skeleton is-animated">
            <div class="b-skeleton-item  image is-3by1" style="border-top-right-radius: 1rem;border-top-left-radius: 1rem;padding-bottom:0;" />
          </div>
        </div>
        <div class="card-content">
          <div class="media">
            <div class="media-content">
                <b-skeleton width="120"  size="is-small" />
                <b-skeleton width="80" size="is-small"  />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'VendorSkeleton'
}
</script>

<style>
.cov {
        overflow-x: scroll;
        -ms-overflow-style: none;
        -moz-overflow-style: none;
        scrollbar-width: none;
}

.cov::-webkit-scrollbar {
  display: none;
}

.cov .media-content {
    overflow: hidden;
}

.card-content  {
    padding: 1rem 1.25rem  !important;
}

.media-content .b-skeleton {
    margin:  0 !important;
}
</style>
