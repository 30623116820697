


export const cart = {
    namespaced: true,
    state: { cart:[] },
    getters: {
        cartCount: (state) => {
            if (!state.cart.length) { return 0 }
            return state.cart.reduce((ac, next) => ac + +next.unit, 0)
          },
        
          totalPrice: (state) => {
            if (!state.cart.length) { return 0 }
            return state.cart.reduce((ac, next) => ac + +next.total, 0)
          },
          totalActualPrice: (state) => {
            if (!state.cart.length) { return 0 }
            return state.cart.reduce((ac, next) => ac + +next.totalPrice, 0)
          },
        
          alreadyInCart: state => (_id) => {
            if (_id) {
              for (let i = 0; i < state.cart.length; i++) {
                if (state.cart[i].id === _id) {
                  return true
                }
        
                //
              }
        
              return false
            }
          },
        
          inCart: state => (_id) => {
            if (_id) {
              for (let i = 0; i < state.cart.length; i++) {
                if (state.cart[i].id === _id) {
                  return state.cart[i]
                }
        
                //
              }
        
              return false
            }
          },
        
          business0nlyCart: state => (business) => {
            if (business) {
              for (let i = 0; i < state.cart.length; i++) {
                if (state.cart[i].business.toLowerCase() === business.toLowerCase()) {
                  return false
                }
        
                //
              }
        
            //   return false
            }
          },

          getCartBusiness: (state) => {
            //
            if (state.cart.length > 0) {
                //
                return state.cart[0].business
            } else {
                return null
            }
          }
    },
    actions: {
        addToCart ({ commit, state }, product) {
            console.log(product)
            // check if product is avaliable
            if (product.available) {
              // if product exist in cart
              const cartItem = state.cart.find(item => item.id === product.id)
        
              // push new product to cart
              if (!cartItem) {
                //
                // context.commit('addToCart', product)
                if (state.cart.length > 0) {
                  //
                  if (state.cart.find(item => item.business === product.business)) {
                    commit('addToCart', product)
                  } else {
                    //
                    return false
                  }
                } else {
                  commit('addToCart', product)
                }
              } else {
                // increment
                commit('updateCart', product)
              }
            }
          },
          emptyCart({ commit, state }) {
            commit('emptyCart')
          },
          lessItemQuantity ({ commit, state }, product) {
            const item = state.cart.find(pro => pro.id === product.id)
        
            if (item.unit > 1) {
              commit('lessItemQuantity', product)
            } else {
              commit('removeFromCart', item.id)
            }
        }
    },
    mutations: {
        addToCart: (state, product) => {
            state.cart.push({
              id: product.id,
              product: product.product,
              business: product.business,
              discount: product.discount,
              image: product.image,
              unit: product.unit,
              price: product.price,
              total: (( product.discount > 0 ? ((100 - product.discount)/100) * product.price : product.price)  * product.unit).toFixed(2),
              totalPrice: (product.price * product.unit).toFixed(2)
            })
          },
          increaseItemQuantity: (state, pro) => {
            //
            const item = state.cart.find(product => product.id === pro.id)
            item.unit++
            item.total = (item.price * item.unit).toFixed(2)
          },
          lessItemQuantity: (state, product) => {
            //
            const item = state.cart.find(pro => pro.id === product.id)
            item.unit--
            item.total = (item.price * item.unit).toFixed(2)
          },
          updateCart: ( state, data) => {
            //
            const product = state.cart.find(pro => pro.id == data.id)
            //
            if (data.unit <= 0) {
                //
                let index = ''
                index = state.cart.findIndex(pro => pro.id === data.id)
                state.cart.splice(index, 1)
                return
            }
            // update
            product.unit = data.unit
            // product.total = (data.price * data.unit).toFixed(2)
            
            product.total = (( data.discount > 0 ? ((100 - data.discount)/100) * data.price : data.price)  * data.unit).toFixed(2)
            product.totalPrice = (data.price * data.unit).toFixed(2)
            // state.cart.find(id => '' + data.id + '').unit = data.unit
            // state.cart.find(id => '' + data.id + '').total = data.total
          },
        
          removeFromCart: (state, id) => {
            let index = ''
            index = state.cart.findIndex(item => item.id === id)
            state.cart.splice(index, 1)
          },
        
          emptyCart: (state) => {
            //
            state.cart = []
          }
    }
}