var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"columns",staticStyle:{"display":"none"}},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"field"},[_c('p',{staticClass:"control has-icons-right"},[_c('gmap-autocomplete',{ref:"search",staticClass:"input",attrs:{"autofocus":"","id":"mapautocomplete","type":"search"},on:{"place_changed":_vm.initMarker,"input":_vm.checkVal}}),_c('span',{staticClass:"icon is-small is-right",on:{"click":_vm.clearSearch}},[_c('i',{staticClass:"fa fa-times-circle"})])],1)]),(_vm.allAddresses.length > 0 && _vm.searchValueEmpty)?_c('div',{staticClass:"pac-container pac-logo hdpi",staticStyle:{"position":"absolute","left":"24px","top":"125px","max-height":"75vh","overflow-y":"auto"}},_vm._l((_vm.allAddresses),function(address){return _c('div',{key:address.address,staticClass:"columns is-mobile pac-item m-0 py-2 px-4",on:{"click":function($event){return _vm.setCachedAddress(address)}}},[(address.address == _vm.getAddress.address)?_c('span',{staticClass:"active-icon is-flex is-align-items-center column is-1 mr-3 p-2"},[_c('b-icon',{attrs:{"icon":"check-circle","type":"is-primary"}})],1):_vm._e(),_c('span',{staticClass:"column p-2"},[_c('span',{staticClass:"pac-item-query"},[_vm._v(" "+_vm._s(address.address.split(',')[0])+" ")]),_c('span',[_vm._v(" "+_vm._s((address.address.split(',')[1] ? address.address.split(',')[1] : '')+' '+(address.address.split(',')[2] ? address.address.split(',')[2] : '')))])])])}),0):_vm._e()])]),_c('div'),_c('gmap-map',{ref:"gmaps",style:(`width:${_vm.width};  height: ${_vm.height};`),attrs:{"options":{ styles: _vm.styles },"zoom":14,"center":_vm.center,"disableDefaultUI":true,"draggableCursor":true},on:{"change":_vm.updateCenter}},[_vm._l((_vm.locationMarkers),function(m,index){return _c('gmap-marker',{key:index,ref:'marker'+index,refInFor:true,attrs:{"icon":{
                            url: require('@/assets/green-marker2.svg'), 
                            scaledSize: {width: 45, height: 45, f: 'px', b: 'px'} 
                        },"position":m.position,"draggable":false,"clickable":true},on:{"click":function($event){_vm.center=m.position}}})}),(_vm.marker2)?_c('gmap-marker',{ref:'marker55',attrs:{"icon":{
                            url: require('@/assets/green-marker.svg'), 
                            scaledSize: {width: 45, height: 45, f: 'px', b: 'px'} 
                        },"position":_vm.marker2Cords,"draggable":false,"clickable":true},on:{"click":function($event){_vm.center=_vm.marker2Cords}}}):_vm._e()],2),_vm._v(" "+_vm._s(_vm.existingPlace)+" ")],1)
}
var staticRenderFns = []

export { render, staticRenderFns }