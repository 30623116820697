<template>
    <div>
            <div class="py-4">
                <ul class="menu-list columns is-multiline" style="">
                    <li v-for="product in products" :key="product.id" class="column is-half-tablet is-one-third-desktop">
                        <a class="columns is-mobile mb-0 my-0 p-0" style="padding:0 10px !important">

                            <span v-if="isControls" class="column is-1 pl-1 mr-5">
                                <b-button :disabled="product.unit == 0" size="is-small" @click="lessunit(product)" rounded icon-left="minus"></b-button>
                            </span>
                            <span @click="redirectTo(product)" class="column p-0 is-one-fifth"> 
                                <b-image v-if="product.image" ratio="4by3" loading="lazy" :src="product.image" :alt="product.image"></b-image>
                                <!-- <b-image ratio="4by3" :src="require(product.image)"></b-image> -->
                                <!-- <b-image ratio="4by3" :src="getImage(product.image)"></b-image> -->
                                <!-- <b-image ratio="4by3" :src="require('@/assets/img/'+product.image)"></b-image> -->
                                <!-- :src="`${post.image}`" :alt="`${post.product}`" -->
                            </span>

                            <span :class="`column  p-3 m-0 ${alreadyInCart(product.id) ? 'inCart' : ''}`"> 
                                <div>
                                    <p @click="redirectTo(product)" class="title mb-1 is-6 has-text-weight-semibold" style="font-weight: 300"> 
                                        <!-- <b-tag v-if="alreadyInCart(product.id)" type="is-primary"> {{ inCart(product.id).unit }} 
                                        </b-tag>  -->
                                        <span> {{ product.unit }}x </span> {{ product.product }}
                                    </p>
                                    <!-- <p  @click="redirectTo(product)" class=" is-6 mb-3">{{ product.details }} </p> -->
                                    <p  @click="redirectTo(product)" v-if="product.discount !== 0" class="small mr-1">
                                        <span class="mr-1 " style="text-decoration:line-through"> GH₵{{ formatPrice(parseFloat(product.price) * product.unit) }} </span> 
                                        <b-tag type="is-primary has-text-weight-semibold " rounded> GH₵{{ product.total }} </b-tag> 
                                    </p>
                                    <span  @click="redirectTo(product)" v-else>GH₵{{ product.total }}</span>
                                    
                                    <div class="columns is-mobile pt-2 pl-3" style="width:25%;">
                                        <div class="column pt-3 pb-1 px-0 mr-3">
                                            
                                        </div>
                                        <!-- <div class="column py-3 m-1"> -->
                                            <!-- <span>{{ getunit(product) }}</span> -->
                                            
                                        <!-- </div> -->
                                        <div class="column pt-3 pb-1 px-0">
                                            
                                        </div>
                                    </div>
                                </div>
                            </span>
                            <span v-if="isControls" class="column is-1  m-0 mr-3">
                                <b-button size="is-small" @click="addunit(product)" rounded icon-left="plus"></b-button>
                            </span>
                        </a>
                        <!-- <hr class="m-0 p-0 mb-2"> -->
                    </li>
                </ul>
            </div>
    </div>
</template>

<script>

    import { mapGetters } from "vuex"

    export default {
        name: 'CartTemplate',
        data() {
            return {
                isCardModalActive: false,
                currentProduct: {},
                screenWidth: 0
            }
        },
        props: {
            products: {
                required: true
            },
            isControls: {
                type: Boolean,
                default: true
            }
        },
        mounted() {
            this.updateScreenWidth();
            this.onScreenResize();
        },
        computed: {
            ...mapGetters('cart', ['totalPrice']),
            ...mapGetters('cart', ['cartCount']),
            ...mapGetters('cart', ['getCartBusiness']),
            
        },
        methods: {
            
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            formatPrice(price) {
                return price.toFixed(2)
            },
            getDiscount(price, disc) {
                return ((100 - disc)/100) * price;
            },
            loadProduct(product){
                this.currentProduct = product
                //open
                this.isCardModalActive = true
            },
            getImage(img) {
                const fullPath = '../assets/img/'+img
                console.log(fullPath)
                return fullPath
            },
            redirectTo (currentProduct) {
                if (!currentProduct.available){
                    return
                }

                this.$router.push({ name: 'product', 
                                    query: { 
                                        id: currentProduct.id,
                                        available: currentProduct.available,
                                        business: currentProduct.business, 
                                        image: currentProduct.image,
                                        product: currentProduct.product, 
                                        discount: currentProduct.discount,
                                        price: currentProduct.price,
                                        type: currentProduct.type,
                                        details: currentProduct.details,
                                        description: currentProduct.description, 
                                    } 
                                })
            },
            alreadyInCart(id) {
                // console.log(this.$store)
                return this.$store.getters['cart/alreadyInCart'](id)
            },
            inCart(id) {
                // console.log(this.$store)
                const cart = this.$store.getters['cart/inCart'](id)
                return cart
            },
            // removeFromCart(id){

            //     this.$buefy.dialog.confirm({
            //                 title: 'Starting a new Order?',
            //                 message: `This will clear your <b>${this.getCartBusiness} </b> Order.`,
            //                 confirmText: 'Confirm',
            //                 type: 'is-danger',
            //                 // hasIcon: true,
            //                 onConfirm: () => {
                            
            //                 }
            //             })
                        
            //     this.$store.dispatch('cart/removeFromCart', id)
            // },
            lessunit(product) {
                // if (this.unit <= 1) {
                
                product.unit--

                let formOutput = {
                    available: true,
                    id: product.id,
                    business: product.business,
                    discount: product.discount,
                    product: product.product,
                    image: product.image,
                    unit: product.unit,
                    price: product.price
                };
                
                this.$store.dispatch('cart/addToCart', formOutput)

                
                // product.totalPrice = product.unit * product.price
                // }
            },
            addunit(product) {

                if (this.getCartBusiness !== null) {

                    if (this.getCartBusiness !== product.business){
                        
                    // })
                        this.$buefy.dialog.confirm({
                            title: 'Starting a new Order?',
                            message: `This will clear your <b>${this.getCartBusiness} </b> Order.`,
                            confirmText: 'Confirm',
                            type: 'is-danger',
                            // hasIcon: true,
                            onConfirm: () => {
                                // empt cart
                                this.$store.dispatch('cart/emptyCart')
                                //
                                product.unit++

                                let formOutput = {
                                    available: true,
                                    id: product.id,
                                    business: product.business,
                                    discount: product.discount,
                                    product: product.product,
                                    image: product.image,
                                    unit: product.unit,
                                    price: product.price
                                };
                                
                                this.$store.dispatch('cart/addToCart', formOutput)

                            }
                        })
                    
                        return
                    }
                }

                product.unit++

                let formOutput = {
                    available: true,
                    id: product.id,
                    business: product.business,
                    discount: product.discount,
                    product: product.product,
                    image: product.image,
                    unit: product.unit,
                    price: product.price
                };
                
                this.$store.dispatch('cart/addToCart', formOutput)
                
                // product.totalPrice = product.unit * product.price
                
            },
            getunit(product) {
                return product.unit
            }
        } 
    }
</script>

<style lang="scss" >// custom
    @import '@/assets/scss/custom_variables.scss';
    
    .modal-close {
        background: gray !important;
    }

    // .inCart {
        // border-left: 4px solid $primary;
    // }

    //  .modal-content {
    //     height: 90% !important;
    //     border-radius: 20px;
    //     margin-top: 20% !important;
    // }

    .order-cover {
        position: fixed;
        z-index: 50;
        width: calc(100% - 50px);
        // left: 0;
        // padding-bottom: 20px;
        margin-bottom: 5px;
        bottom: 10px;
    }
</style>