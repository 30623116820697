<template>
    <div>
        <div class="sidebar-wrapper">
            <SideNav />
        </div>
        <div class="p-1">
            <div class="p-3 px-4">
                <div class="p-3">
                    <div class="card p-5">
                        <div class="columns is-mobile">
                            <div class="column is-6">
                                <p class="title is-5"> Orders </p>
                                <!-- <h4 class="subtitle is-5">Total</h4> -->
                            </div>
                            <div class="column is-flex is-align-items-center is-justify-content-right	">
                                <h4 class="has-text-weight-medium">GHC{{ getTotals(confirmed).toFixed(2) }}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-3 px-4" style="display:">
                <nav class="level  is-mobile">
                    <div class="level-item has-text-centered">
                        <div>
                            <!-- {{getOrders}} -->
                        <p class="heading">Pending</p>
                        <p class="title">{{ pending.length }}</p>
                        <!-- <p class="is-size-6"> GHC{{ getTotals(pending).toFixed(2) }}</p> -->
                        </div>
                    </div>
                    <div class="level-item has-text-centered">
                        <div>
                        <p class="heading">Confirmed</p>
                        <p class="title">{{ confirmed.length }}</p>
                        
                        <!-- <p class="is-size-6"> GHC{{ getTotals(confirmed).toFixed(2) }}</p> -->
                        <!-- <p class="">GHC 500</p> -->
                        </div>
                    </div>
                    <!-- <div class="level-item has-text-centered">
                        <div>
                        <p class="heading">Cancelled</p>
                        <p class="title">{{ cancelled.length }}</p>
                        <p class="is-size-6"> GHC{{ getTotals(cancelled).toFixed(2) }}</p>
                        </div>
                    </div> -->
                    <!-- <div class="level-item has-text-centered">
                        <div>
                        <p class="heading">Completed</p>
                        <p class="title">13</p>
                        <p class="">GHC 500</p>
                        </div>
                    </div> -->
                </nav>
            </div>
            <div class="p-3 px-4">
                
                <div class="p-3">
                    <div class="card p-5">
                        <div class="columns is-mobile">
                            <div class="column is-6">
                                <p class="title is-5"> Products </p>
                                <!-- <h4 class="subtitle is-5">Total (0)</h4> -->
                            </div>
                            <div class="column is-flex is-align-items-center is-justify-content-right	">
                                <h4 class="has-text-weight-medium">{{allProducts.length}}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="p-3">
                    <div class="card p-5">
                        <div class="columns is-mobile">
                            <div class="column is-6">
                                <p class="title is-5"> Orders </p>
                                <h4 class="subtitle is-5">Total ({{ confirmed.length }})</h4>
                            </div>
                            <div class="column is-flex is-align-items-center is-justify-content-right	">
                                <h4 class="has-text-weight-medium">GHC{{ getTotals(confirmed).toFixed(2) }}</h4>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="p-3">
                    <div class="card p-5">
                        <div class="columns is-mobile">
                            <div class="column is-6">
                                <p class="title is-5"> Wallet </p>
                                <!-- <h4 class="subtitle is-5">Total (0)</h4> -->
                            </div>
                            <div class="column is-flex is-align-items-center is-justify-content-right	">
                                <h4 class="has-text-weight-medium">GHC 0.00</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- <MainFooter/> -->
    </div>
</template>

<script>

    import MainFooter from '../components/footer'
    import SideNav from '../components/sidenav'

    import { mapGetters, mapActions} from 'vuex'


    export default {
        name: "InsightPage",
        components: {
            // MainFooter,
            SideNav
        },
        computed: {
            ...mapGetters('auth', ['getUser']),
            ...mapGetters('orders', ['getOrders']),
            ...mapGetters('orders', ['pending']),
            ...mapGetters('orders', ['confirmed']),
            ...mapGetters('orders', ['completed']),
            ...mapGetters('orders', ['cancelled']),
            ...mapGetters('products', ['allProducts']),
        },
        mounted() {
            this.getProducts()
        },
        methods: {
            getTotals(obj){
               const sum  = obj. reduce((accumulator, object) => {
                    return accumulator + object.totalPrice;
                }, 0)

                return sum
            },
            async getProducts() {
                await this.$store.dispatch('products/getProducts', this.getUser.business)
                
            },
        }
    }
</script>

<style lang="scss" scoped>
    .card {
        border: 1px solid rgba(10, 10, 10, 0.1) !important;
        box-shadow: none;
    }
</style>