//
import { GET_ALL_PRODUCTS_QUERY, GET_PRODUCTS_QUERY, GET_PRODUCT_QUERY, CREATE_PRODUCT_MUTATION, UPDATE_PRODUCT_MUTATION, PRODUCT_VISIBLE_MUTATION, PRODUCT_INVISIBLE_MUTATION, PRODUCT_AVAILABLE_MUTATION, PRODUCT_UNAVAILABLE_MUTATION } from '../../graphql/product'
import { apolloClient } from '../../graphql/apollo'
import { store } from '../../store'


export const products = {
    namespaced: true,
    state: {
        products: [],
        newProduct: null,
        updateProduct: null
     },
    getters: {
        allProducts: (state) => {
            //
            return state.products
        },
        getProduct: (state) => {
            //
            const data = state.products
        
            return data
        },
        searchProducts: state => (search) => {
            //
            const results = state.products.filter(({ product, business, category, tags }) => product.toLowerCase().includes(search.toLowerCase()) || business.toLowerCase().includes(search.toLowerCase()) || category.toLowerCase().includes(search.toLowerCase()) || tags.includes(search.toLowerCase()))

            // const allBusiness =  results.map(product => product.product)
            // const businesses  = [...new Set(allBusiness)]

            return results
            
        },
        searchBusinessProduct: state => (search) => {
            //
            const results = state.products.filter(({ product }) => product.toLowerCase().includes(search.toLowerCase()) )

            return results
        },
        newProduct: (state) => {
            return state.newProduct
        },
        updateProduct: (state) => {
            return state.updateProduct
        }
        // currentBusiness: state => (name) => {
        //     // if (business) { return }
        
        //     const results = state.vendors.find(({ business }) => business.includes(name))
        
        //     return results
        // }
    },
    actions: {
        async getProducts({ state,commit },  business ) {
            //
            let resp
            let data
            //
            if (business) {
                resp = await apolloClient.query({ query: GET_PRODUCTS_QUERY, variables: { business: business } })
                data = resp.data.allProducts

            }  else {
                //
                resp = await apolloClient.query({ query: GET_ALL_PRODUCTS_QUERY })
                data = resp.data.products
            }

            (function () {
                for (let index = 0; index < data.length; index++) {
    
                    // to 2 decimalplaces
                    data[index].qty = 0
                }
                })()

            commit('updateProducts', data)
        },
        async createProduct({ commit }, product){

            
            const resp = await apolloClient.mutate({ mutation: CREATE_PRODUCT_MUTATION, variables: product })
            const data = resp.data.createProduct

            if (data) {
                //
                commit('createProduct', data)

                // fetch products
                store.dispatch('products/getProducts', product.business)
            }

            // console.log(data)

            // return resp
            
        },
        async updateProduct({ commit }, { product, business, update }){

            
            const resp = await apolloClient.mutate({ mutation: UPDATE_PRODUCT_MUTATION, variables: { product:product, business: business, update:update } })
            const data = resp.data.updateProduct

            if (data) {
                //
                commit('updateProduct', data)

                // fetch products
                store.dispatch('products/getProducts', business)
            }

            // console.log(data)

            // return resp
            
        },
        clearNewUpdate({ commit }, type){

            //
            if (type=='new'){
                commit('clearNew')
            }else if (type =='update'){
                commit('clearUpdate')
            }

        },
        async productVisible({ commit }, { product, business }){
            
            console.log(product)
            console.log(business)

            if (product || business) {
                const resp = await apolloClient.mutate({ mutation: PRODUCT_VISIBLE_MUTATION, variables: { product: product, business: business } })
                const data = resp.data.productVisible

                console.log(data)
            }

        },
        async productInvisible({ commit }, { product, business }){

            console.log(product)
            console.log(business)

            if (product || business) {
                const resp = await apolloClient.mutate({ mutation: PRODUCT_INVISIBLE_MUTATION, variables: { product: product, business: business } })
                const data = resp.data.productInvisible

                console.log(data)
            }

            

        },
        async productAvailable({ commit }, { product, business }){
            if (product || business) {
                const resp = await apolloClient.mutate({ mutation: PRODUCT_AVAILABLE_MUTATION, variables: { product: product, business: business } })
                const data = resp.data.productAvailable

                console.log(data)
            }  

        },
        async productUnavailable({ commit }, { product, business }){
            if (product || business) {
                const resp = await apolloClient.mutate({ mutation: PRODUCT_UNAVAILABLE_MUTATION, variables: { product: product, business: business } })
                const data = resp.data.productUnavailable

                console.log(data)
            }

        },
    },
    mutations: {
        updateProducts: (state, data) => {
            state.products = data
        },
        createProduct: (state, data) => {
            state.newProduct = data
        },
        updateProduct: (state, data) => {
            state.updateProduct = data
        },
        clearNew: (state) => {
            state.newProduct = null
        },
        clearUpdate: (state) => {
            state.updateProduct = null
        }
    }
}