<template>
    <div>
        <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true"></b-loading>
        <div class=" p-5">
            
            <feather size="30" @click="goBack" type="arrow-left" class="my-5 mx-2 dark" style="top:0;position:absolute" ></feather>
            <feather @click="emptyCart" size="30" type="trash" class="mx-5 mx-2 dark" style="right:0;position:absolute" ></feather>
            <h4 class="title is-4 ml-5 pl-5">Checkout </h4>
            
            <div class="p-3">
                <h4 class="title is-4"> Order Summary </h4>
                <p class="subtitle "> {{ getCartBusiness }}</p>
            </div>
            <div class="card">
                <!-- {{items }} -->
            </div>
            <CartTemplate :products="items"/>
            <!--  -->
            <!-- Total -->
            <div class="card p-3">
                <h4 class="title is-5 p-2 pt-3">Total</h4>

                <div class="px-2">
                    <div v-if="(totalActualPrice !== totalPrice)" class="columns is-mobile mb-0">
                        <div class="column">
                            <p>Savings</p>
                        </div>
                        <div class="column has-text-right">
                            <p>(GH₵{{ (totalActualPrice - totalPrice).toFixed(2) }})</p>
                        </div>
                    </div>
                    <div class="columns is-mobile mb-0">
                        <div class="column">
                            <p class="has-text-weight-semibold">Subtotal</p>
                        </div>
                        <div class="column has-text-right">
                            <p class="has-text-weight-semibold">GH₵{{ (totalPrice).toFixed(2) }}</p>
                        </div>
                    </div>
                    <div class="columns is-mobile mb-0">
                        <div class="column">
                            <p class="">Delivery fee</p>
                        </div>
                        <div class="column has-text-right">
                            <p class="">{{ (getBusiness.freeDelivery ? 'FREE' : 'GH₵ '+getBusiness.deliveryFee.toFixed(2)) }}</p>
                        </div>
                    </div>
                    <hr class="my-3 p-0">
                    <div class="columns is-mobile mb-0">
                        <div class="column">
                            <p class="has-text-weight-bold is-5">Total</p>
                        </div>
                        <div class="column has-text-right">
                            <p class="has-text-weight-bold is-5">GH₵{{ (getBusiness.freeDelivery ? totalPrice : totalPrice + getBusiness.deliveryFee).toFixed(2) }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card p-3 my-2 " >
                <div style="display:flex;align-content: center;align-items: center">
                    <feather type="map-pin" class="p-2"></feather> 
                    <p icon class=" is-5 is-grey has-text-weight-medium "> Spintex Road, Coatal Junction... </p>
                </div>

                <figure class="image image is-2by1 mx-4 my-2">
                    <img src="https://bulma.io/images/placeholders/640x320.png">
                    <!-- <GoogleMaps style="max-width:460px;"/> -->
                </figure>
                <div class="mx-4 my-2 py-2">
                    <b-field>
                        <b-input placeholder="Delivery note" ></b-input>
                    </b-field>
                </div>
            </div>

            <div class="card p-3 my-2" style="display:flex;align-content: center;align-items: center">
                <feather type="clock" class="p-2"></feather>
                <h4 class=" is-6 has-text-weight-medium"> {{ getBusiness.deliveryTime }} </h4>

                <div class="px-2">
                </div>
            </div>

            <div class="card p-3 my-2">
                <h4 class="title is-5 p-2 pt-3">Payment</h4>

                <div style="display:flex;align-content: center;align-items: center">
                    <feather type="credit-card" class="p-2"></feather> 
                    <p icon class=" is-5 is-grey has-text-weight-medium " @click="isOpen = !isOpen"> Select payment method </p>

                   
                </div>

                 <section>

                        <b-collapse animation="slide" :open="false" aria-id="contentIdForA11y1" v-model="isOpen">
                            <div class=" m-2" >
                                <div class="pl-0 p-1">
                                    <div style="display:flex;align-content: center;align-items: center">

                                    </div>
                                    <!-- <p class="link p-2 px-3">Cash  </p>  -->
                                    <div class="link p-2 px-5">Mobile Money  </div> 
                                    <p class="link p-2 px-5">Add New Card</p>
                                </div>
                            </div>
                        </b-collapse>

                    </section>
            </div>
            
            <div class="card p-4 my-2">
                <div class="columns is-mobile p-2 pb-0">
                        <div class="column">
                            <p class="title is-5 has-text-weight-semibold">Total</p>
                        </div>
                        <div class="column has-text-right">
                            <p class="title is-5 has-text-weight-semibold">GH₵{{ (getBusiness.freeDelivery ? totalPrice : totalPrice + getBusiness.deliveryFee).toFixed(2) }}</p>
                        </div>
                </div>
                <b-button @click="completeOrder" type="is-primary" class="p-3 has-text-weight-medium is-5 title" size="is-medium" rounded expanded> Confirm Order</b-button>
            </div>
            
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex"
    import CartTemplate from '../components/CartTemplate'
    import GoogleMaps from '../components/GoogleMaps'
    

    export default {
        name: "CheckoutPage",
        data() {
            return {
                isFullPage: true,
                business: '',
                businessData: null,
                isOpen: true,
                isLoading: false
            }
        },
        components: {
            CartTemplate,
            // GoogleMaps
        },
        computed: {
            ...mapState('cart', { items: state=> state.cart }),
            ...mapGetters('cart', ['totalPrice']),
            ...mapGetters('cart', ['totalActualPrice']),
            ...mapGetters('cart', ['getCartBusiness']),
            ...mapGetters('cart', ['cartCount']),
            getBusiness() {
                return this.$store.getters['vendors/currentBusiness'](this.business)
            }
        },
        mounted() {
            this.business = this.$store.getters['cart/getCartBusiness']

            if (this.cartCount <= 0) {
                this.$router.push('/index')
            }
        },
        methods: {
            goBack() {
                this.$router.back()
            },
            emptyCart() {

                this.$buefy.dialog.confirm({
                            title: 'Empty Cart',
                            message: `Are you sure you want to delete all items in you cart.`,
                            confirmText: 'Confirm',
                            type: 'is-danger',
                            // hasIcon: true,
                            onConfirm: () => {
                                this.$store.dispatch('cart/emptyCart')
                                this.$router.push('/index')
                            }
                        })

                
            },
            completeOrder() {

                this.$buefy.dialog.confirm({
                            title: 'Comfirm Order',
                            message: `Are you sure you want to place this order.`,
                            confirmText: 'Place',
                            type: 'is-primary',
                            // hasIcon: true,
                            onConfirm: () => {
                                this.isLoading = true
                                // this.$store.dispatch('cart/emptyCart')
                                setTimeout(()=>{
                                    this.isLoading = false

                                }, 1500)
                            }
                        })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .link:hover, .link:focus {
        background: grey;
        background: #f4f4f4;
        border-radius: 1.2rem;
    }
</style>