
const page = localStorage.getItem('pages') ? JSON.parse(localStorage.getItem('pages')) :  false;
const initialState = page
    ? page
    : {
        index: null,
        vendor: null,
        search: null
    };

export const pages = {
    namespaced: true,
    state: initialState,
    getters: {
        getPage: state => (link) => {
            return state[link]
        }
    },
    actions: {
        setPage({ commit, state }, data){
            //
            if (data) {
                commit('setPage', data)
            }
        }
    },
    mutations: {
        setPage: (state, data ) => {
            console.log(state)
            state[data.link] = data.data
        }
    }
}