import gql from "graphql-tag";

export const GET_BUSINESS_QUERY = gql`
  query businesses {
    businesses {
        _id
        business
        type
        coverImage
        location
        country
        freeDelivery
        deliveryFee
        deliveryTime
        menuDiscount
        ratings
    }
  }` 

export const GET_LOCATION_BUSINESS_QUERY = gql`
  query getBusiness($location: [Float]!) {
    getBusiness(location: $location) {
        _id
        business
        type
        coverImage
        location
        country
        freeDelivery
        deliveryFee
        deliveryTime
        menuDiscount
        ratings
    }
  }
` 
