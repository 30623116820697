import { GET_ORDER_QUERY, GET_BUSINESS_ORDER_QUERY } from '../../graphql/order'
import { apolloClient } from '../../graphql/apollo'
import { store } from '../../store'


const allOrders = localStorage.getItem('orders') ? JSON.parse(localStorage.getItem('orders')) :  false;
const initialState = allOrders
    ? { orders: allOrders }
    : { orders:[] };

export const orders = {
    namespaced: true,
    state: initialState,
    getters: {
          getOrders: (state) => {
                const allOrders = state.orders
                allOrders.sort((a, b) => b.createdAt - a.createdAt)

                return allOrders
          },
          getOrder: (state) => (orderNo) => {
            const allorders = state.orders
            const found = allorders.find((order) => order.orderNo == orderNo)

            return found
          },
          assigned: (state) => {
            //
            const data = state.orders
            const assignedContent = data.filter((order)=>  order.courierAssigned !== null && order.status == 'assigned')
            // sort in desc
            // assignedContent.sort((a, b) => b.createdAt - a.createdAt)

            return assignedContent
          },
          delivering: (state) => {
            //
            const data = state.orders
            const confirmedContent = data.filter((order)=> order.courierDelivering !== null && order.status == 'delivering')
            // sort in desc
            confirmedContent.sort((a, b) => b.createdAt - a.createdAt)

            return confirmedContent
          },
          delivered: (state) => {
            //
            const data = state.orders
            const completedContent = data.filter((order)=> order.orderDelivered !== null)
            // sort in desc
            completedContent.sort((a, b) => b.createdAt - a.createdAt)
        
            return completedContent
          },
          cancelled: (state) => {
            //
            const data = state.orders
            const cancelledContent = data.filter((order)=> order.cancelled !== null)
            // sort in desc
            cancelledContent.sort((a, b) => b.createdAt - a.createdAt)
        
            return cancelledContent
          },

    },
    actions: {
        async Order({ state,commit }, data ) {
            //
            commit('place', data)

        },
        async getOrders({ state,commit } ) {
            //
            let resp
            let data

            const user = store.getters['auth/getUser'];
            // console.log(user)
            //
            if (user) {
                resp = await apolloClient.query({ query: GET_ORDER_QUERY, variables: { courier: user.firstname+' '+user.lastname } })
                data = resp.data.allOrders.filter((order) => order.payment !== null || order.payment.status == "success")

                if (data) {
                    commit('updateOrders', data)
                }
                
            }
            
        }
    },
    mutations: {
        place: (state, data) => {
            state.newOrder = data
        },
        updateOrders: (state, data) => {
            state.orders = data
        },
    }
}