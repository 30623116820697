// apollo.js

import Vue from 'vue'
import { setContext } from "apollo-link-context";
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { onError } from "apollo-link-error"
import { InMemoryCache } from 'apollo-cache-inmemory'
import VueApollo from 'vue-apollo'
import { authHeader } from '../helpers/auth-header'

const httpLink = new HttpLink({
    // uri: 'http://localhost:4000/graphql'
    // uri: 'http://192.168.0.172:4000/graphql'
    uri: 'https://greenserver.fly.dev/graphql'
    // uri: 'http://192.168.1.113:4000/graphql'
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from ApplicationSettings if it exists
  const bearer = authHeader()['Authorization'];
  // return the headers to the context so HTTP link can read them
  return {
    headers: {
      ...headers,
      authorization: bearer
    }
  };
});

// Error Handling
const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
            console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
        )
    if (networkError) console.log(`[Network error]: ${networkError}`)
})

// 
// const options = { 
//                     typePolicies: {
                        
//                     }
//                 }

// Create the apollo client
export const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    // link: errorLink.concat(httpLink),
    cache: new InMemoryCache(),
    connectToDevTools: true,
    defaultOptions: {
        watchQuery: { fetchPolicy: 'no-cache' },
        query: { fetchPolicy: 'no-cache' }
    }
})

// Install the Vue plugin
Vue.use(VueApollo)

export const apolloProvider = new VueApollo({
    defaultClient: apolloClient
})