// import config from 'config';
import { authHeader } from '../helpers';
import Userauth from '../auth/index';
import axios from 'axios'

export const userService = {
    login,
    verify,
    refresh,
    logout,
    getAll
};

function login(code, contact, password, remember) {

    return  axios.post('/courier/login', { code: code, contact: contact, remember: remember, })
        .then( response =>{

            const courier = response.data

            // if (user.token) {
            //     // store user details and jwt token in local storage to keep user logged in between page refreshes
            //     localStorage.setItem('user', JSON.stringify(user));
                
            //     // set token in all request
            //     axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`
            // }
            // if (courier.token) {
                // set login
                // Userauth.login(response.data, remember)
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                // localStorage.setItem('user', JSON.stringify(user));
                
                // set token in all request
                // axios.defaults.headers.common['Authorization'] = `Bearer ${courier.token}`
            // }

            return courier;

        })
}

function verify(code, contact, remember, OTP, type) {

    return  axios.post('/courier/verify', { code: code, contact: contact, remember: remember, OTP: OTP, type: type })
        .then( response =>{

            const courier = response.data

            if (courier.token) {
                // set login
                Userauth.login(response.data, remember)
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                // localStorage.setItem('user', JSON.stringify(user));
                
                // set token in all request
                axios.defaults.headers.common['Authorization'] = `Bearer ${courier.token}`
            }

            return courier;

        })
}

function refresh(refresh_token) {
    
    return axios.post('/courier/refresh', { refresh_token: refresh_token })
    .then( response =>{

        const data = response.data

        if (data.token) {
            //
            Userauth.refreshToken(data)
            // store user details and jwt token in local storage to keep user logged in between page refreshes
        //    localStorage.setItem('user', JSON.stringify(user));
                
            // set token in all request
            axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`
        }

        return data;

    })
}

function logout() {

    // validate
    const User = JSON.parse(localStorage.getItem('user'))
    //
    if (!User) {
        //
        return true
    }

    const { refresh_token } = User

    return  axios.post('/courier/logout', { refresh_token: refresh_token } )
        .then( response =>{
            
            // remove user from local storage to log user out
            Userauth.logout()

            return response;

        }).catch(error =>{ 
            // remove user from local storage to log user out
            Userauth.logout()

            return error;
        })
    
}

function getAll() {

    //
    return axios.get('/courier/profile', { headers: authHeader() })
    .then( response =>{
        return response.data
    })
}
