<template>
    <div>
        <div class="p-5">
            <!-- <router-link hidden class="black  mx-2 px-4" to="/mapview" style="display:flex;">
                    <feather size="22" class="py-3 px-2 is-primary" type="map-pin"></feather> 
                    <p class="py-3 mr-3 is-dark"> Teshie Nungua Estate </p> 
                </router-link> -->
            <!-- {{ getPage }} -->
            <div class="p-2">
                    <div class="" style="max-width:460px;">
                        <b-field label="">
                            <b-input autocomplete="0" @input="Search" @keyup.native="load" :loading="loading" autofocus v-model="searchString" placeholder="Vendors or products " icon-right="close-circle" icon-right-clickable @icon-right-click="clearSearch" type="search"></b-input>
                        </b-field>
                    </div>
                    <ul v-if="searchString.length !== 0"  class="menu-list" style="max-width:460px;">
                        <li v-for="business in data" :key="business">
                            
                            <a @click="gotoPage(business)" class="my-1">
                                <p class="title is-6" style="font-weight: 300">{{ business }}</p>
                                <!-- <p class="subtitle is-6">Kpeshie</p> -->
                            </a>
                            <hr class="m-0 p-0">
                        </li>
                        <!-- <li>
                            <a class="my-1">
                                <p class="title is-6" style="font-weight: 300">Airport City</p>
                                <p class="subtitle is-6">Accra</p>
                            </a>
                            <hr class="m-0 p-0">
                        </li> -->
                    </ul>
                    <ul v-else  class="menu-list" style="max-width:460px;">
                        <li v-for="business in set" :key="business">
                            
                            <a @click="setSearch(business)" class="my-1">
                                <p class="title is-6" style="font-weight: 300">{{ business }}</p>
                                <!-- <p class="subtitle is-6">Kpeshie</p> -->
                            </a>
                            <hr class="m-0 p-0">
                        </li>
                    </ul>
            </div>
        </div>
        <MainFooter/>
    </div>
</template>

<script>

    import MainFooter from '../components/footer'
    import { mapGetters } from "vuex"

    export default {
        name: "SearchPage",
        data() {
            return {
                searchString: '',
                loading: false,
                data: [],
                set: [
                    'Fruit', 'Vegetable', 'Fish', 'Meat', 'Poultry', 'Shakes', 'Smoothies', 'Juices'
                ]
            }
        },
        components: {

            MainFooter
        },
        computed: {
            // ...mapGetters['pages/getPage', 'search'],
        },
        mounted() {
             this.getData()
             const data = this.$store.getters['pages/getPage']('search')

             if (data !== null) {
                this.searchString = data.search
                this.data = data.data
             }
        },
        watch: {
            searchString() {
                if (this.searchString.length == 0) {
                    this.data = []
                }
            },
            data() {
                if (this.data.length > 0) {
                    
                    const data = {
                        link: 'search',
                        data: {
                            data: this.data,
                            search: this.searchString,
                        }
                    }
                    
                    this.$store.dispatch('pages/setPage', data)
                }
            }
        },
        methods: {
            clearSearch(){
                this.searchString = ''
                const data = {
                        link: 'search',
                        data: {
                            data: this.data,
                            search: this.searchString,
                        }
                }
                
                this.$store.dispatch('pages/setPage', data)
            },
            setSearch(business) {
                this.searchString = business
                this.Search()
            },
            async Search() {
                // console.log('here')
                if (this.searchString.length >= 3) {
                    
                   
                    const result = await this.$store.getters['products/searchProduct'](this.searchString)

                    this.data = result
                    
                }
            },
            async getData() {
                
               await this.$store.dispatch('products/getProducts', null)
               await this.$store.dispatch('vendors/getBusiness',  null)
            },
            load() {
                this.loading = true 
                setTimeout(()=>{
                    this.loading = false
                }, 500)
            },
            gotoPage(business) {
                //
                const bdata = this.$store.getters['vendors/currentBusiness'](business)
                //
                const query = {
                                business: bdata.business, 
                                type: bdata.type, 
                                deliveryFee: bdata.deliveryFee, 
                                deliveryTime: bdata.deliveryTime, 
                                freeDelivery: bdata.freeDelivery, 
                                rate: bdata.rate, 
                                coverImage: bdata.coverImage
                            }
                //
                this.$router.push({ name: 'vendor', query: query })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>