import Vue from "vue";
import Vuex from "vuex";

import { auth } from './modules/auth'
import { products } from './modules/products'
import { cart } from './modules/cart'
import { orders } from './modules/orders'
import { vendors } from './modules/vendors'
import { pages } from './modules/pages'

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production'

export const store = new Vuex.Store({
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
      auth,
      products,
      cart,
      orders,
      vendors,
      pages,
    },
});