import gql from "graphql-tag";

export const GET_ALL_PRODUCTS_QUERY = gql`
    query products {
            products {
            _id
            product
            category
            tags
            business
            image
            price
            discount
            type
            description
            details
            available
        }
    }
    ` 

export const GET_PRODUCTS_QUERY = gql`
  query allProducts($category: String, $business: String, $discount: String, $tags: String, $available: Boolean) {
    allProducts(category: $category, business: $business, discount: $discount, tags: $tags, available: $available) {
            _id
            product
            category
            tags
            business
            image
            price
            discount
            type
            description
            details
            available
            visible
            by
    }
  }
` 

export const GET_PRODUCT_QUERY = gql`
  query product($product: String) {
    product(product: $product) {
            _id
            product
            category
            tags
            business
            image
            price
            discount
            type
            description
            details
            available
            visible
            by
    }
  }
` 

export const CREATE_PRODUCT_MUTATION = gql`
  mutation createProduct($product: String!, $category: String!, $tags: [String], $business: String!, $image: String, $price: Float!, $discount: Int, $type: String!, $description: String, $details: String, $by: String ) {
    createProduct(product: $product, category: $category, tags: $tags, business: $business, image: $image, price: $price, discount: $discount, type: $type, description: $description, details: $details, by: $by) {
            _id
            product
            category
            tags
            business
            image
            price
            discount
            type
            description
            details
            available
            visible
            by
    }
  }
` 


export const UPDATE_PRODUCT_MUTATION = gql`
  mutation updateProduct($product: String!, $business: String!, $update: productInput) {
    updateProduct(product: $product, business: $business, update: $update) {
            _id
            product
            category
            tags
            business
            image
            price
            discount
            type
            description
            details
            available
            visible
            by
    }
  }
` 

export const PRODUCT_VISIBLE_MUTATION = gql`
  mutation productVisible($product: String!, $business: String!) {
    productVisible(product: $product, business: $business) {
            _id
            product
            category
            tags
            business
            image
            price
            discount
            type
            description
            details
            available
            visible
            by
    }
  }
` 

export const PRODUCT_INVISIBLE_MUTATION = gql`
  mutation productInvisible($product: String!, $business: String!) {
    productInvisible(product: $product, business: $business) {
            _id
            product
            category
            tags
            business
            image
            price
            discount
            type
            description
            details
            available
            visible
            by
    }
  }
` 

export const PRODUCT_AVAILABLE_MUTATION = gql`
  mutation productAvailable($product: String!, $business: String!) {
    productAvailable(product: $product, business: $business) {
            _id
            product
            category
            tags
            business
            image
            price
            discount
            type
            description
            details
            available
            visible
            by
    }
  }
` 

export const PRODUCT_UNAVAILABLE_MUTATION = gql`
  mutation productUnavailable($product: String!, $business: String!) {
    productUnavailable(product: $product, business: $business) {
            _id
            product
            category
            tags
            business
            image
            price
            discount
            type
            description
            details
            available
            visible
            by
    }
  }
` 
