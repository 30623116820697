<template>
    <div>
        <div class="sidebar-wrapper">
            <SideNav feather="plus-circle" :action="addProduct" />
        </div>
        <div class="p-3">
            <b-tabs position="is-centered" type="is" >
                <b-tab-item color="is-success" icon="account" label="By You">
                        <template #header>
                            <!-- <b-icon icon="account"></b-icon> -->
                            <span> All Products <b-tag rounded> {{ allProducts.length }} </b-tag> </span>
                        </template>
                    <!-- <OrderTemplate v-if="pending.length > 0" :orders="pending" /> -->
                        <div class="pt-2 ">
                            <div class="py-3" style="max-width:460px;">
                                    <b-field label="">
                                        <b-input autocomplete="0" @input="Search" @keyup.native="load" :loading="loading" autofocus v-model="searchString" placeholder="Search products "></b-input>
                                    </b-field>
                            </div>
                            
                            <!--  -->
                            <b-tabs v-if="getCategories.length > 0" position="is-centered" type="is" size="is-">
                                <b-tab-item v-for="cat in getCategories" :key="cat" color="is-success" :label="cat">
                                    <template #header>
                                        <!-- <b-icon icon="account"></b-icon> -->
                                        <span> {{ cat }} <b-tag rounded> {{ (allProducts.filter((pro) => pro.category == cat)).length }} </b-tag> </span>
                                    </template>
                                    <!--  -->
                                    <ProductTemplate :edit="edit" :products="allProducts.filter((pro) => pro.category == cat)" :categoryView="false" v-if="(allProducts.filter((pro) => pro.category == cat)).length > 0 && result.length == 0" />
                                </b-tab-item>
                            </b-tabs>
                            
                            <ProductTemplate :edit="edit" :products="result" :categoryView="false" v-if="result.length > 0" />
                            <!-- <b-loading v-model="isLoading" :can-cancel="true"></b-loading> -->
                            <!-- <ProductTemplate :products="getUserProducts" v-if="getUserProducts.length > 0 && result.length == 0" /> -->
                            <!-- <ProductSkeleton v-else/> -->
                        </div>
                </b-tab-item>

                <!-- <b-tab-item v-if="true" color="is-success" icon="account-star" label="By Admin">
                    <template #header>
                            <span> By Admin <b-tag rounded> {{ getAdminProducts.length }} </b-tag> </span>
                        </template>
                        
                        <div class="pt-2 ">
                            <div class="py-3" style="max-width:460px;">
                                    <b-field label="">
                                        <b-input autocomplete="0" @input="Search" @keyup.native="load" :loading="loading" autofocus v-model="searchString" placeholder="Search products "></b-input>
                                    </b-field>
                            </div>

                            <b-tabs v-if="getCategories.length > 0" position="is-centered" type="is" size="is-">
                                <b-tab-item v-for="cat in getCategories" :key="cat" color="is-success" :label="cat">
                                    <template #header>
                                        <span> {{ cat }} <b-tag rounded> {{ (getAdminProducts.filter((pro) => pro.category == cat)).length }} </b-tag> </span>
                                    </template>
                                    <ProductTemplate :edit="edit" :products="getAdminProducts.filter((pro) => pro.category == cat)" :categoryView="false" v-if="(getAdminProducts.filter((pro) => pro.category == cat)).length > 0 && result.length == 0" />
                                    </b-tab-item>
                            </b-tabs>
                            <ProductTemplate :edit="edit" :products="result" :categoryView="false" v-if="result.length > 0" />
                        </div>
                </b-tab-item> -->

                <b-tab-item v-if="true" color="is-success" icon="account-star" label="Categories">
                    <template #header>
                            <!-- <b-icon icon="account-star"></b-icon> -->
                            <span> Categories <b-tag rounded> {{ getCategories ? getCategories.length : 0 }} </b-tag></span>
                        </template>

                        <ul class="menu-list" style="max-width:460px;">
                            <li v-for="cat in getCategories" :key="cat">
                                
                                <a class="my-1">
                                    <p class="title is-6 has-text-weight-normal" style="font-weight: "><b-tag style="float:right" rounded> {{ allProducts.filter((pro)=>pro.category == cat).length }} </b-tag> {{ cat }} </p>
                                    <!-- <p class="subtitle is-6">Kpeshie</p> -->
                                </a>
                                <hr class="m-0 p-0">
                            </li>
                            <li>
                                
                                <a class="my-1">
                                    <p class="title is-6" style="font-weight: "><b-tag style="float:right" rounded> {{ allProducts.length }} </b-tag> Total </p>
                                    <!-- <p class="subtitle is-6">Kpeshie</p> -->
                                </a>
                                <hr class="m-0 p-0">
                            </li>
                        </ul>
                </b-tab-item>
                
            </b-tabs>
        </div>
        
        <b-modal v-if="isCardModalActive" v-model="isCardModalActive" width="460px"  scroll="keep">
            <div class="p-4 has-bg-whte" >
                <div class="content p-1 mb-1">
                    <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="true"></b-loading>
                    <!-- <hr> -->
                    <div class="p-5 card ">
                        <div class="pt-4 ">
                            <h4 v-if="!editProduct" class="title is-4 px-2 mb-1"> Add Product</h4>
                            <h4 v-else class="title is-4 px-2 mb-1"> Edit Product: {{ form.product }} </h4>
                        </div>
                        <div class="p-3">
                            <b-field v-if="!editProduct">
                                <b-switch v-model="form.visible">Visible</b-switch>
                            </b-field>
                        </div>
                        <figure v-if="dropFile" class="b-image-wrapper image is-16by9 m-1">
                            <img
                                
                                :src="img"
                                ratio="16by9"
                            />
                        </figure>
                        
                        <b-field v-if="!editProduct" :type="im.type" :message="im.message">
                            <!-- <b-progress type="is-main" v-if="uploadProgress" :value="uploadProgress" show-value format="percent"></b-progress> -->
                            <span style="font-size:0.85rem" v-if="uploadProgress > 0"> Kindly wait, files being uploaded</span>
                            <b-upload v-if="dropFile == null" v-model="dropFile"
                                drag-drop
                                accept="image/png,image/jpeg"
                                expanded>
                                <section class="section p-3">
                                    <div class="content has-text-centered">
                                        <p class="mb-0">
                                            <b-icon
                                                icon="upload"
                                                size="is-">
                                            </b-icon>
                                        </p>
                                        <p>Drag & Drop image / upload</p>
                                    </div>
                                </section>
                            </b-upload>
                        </b-field>
                        <span v-if="dropFile" class="tag is-primary mb-2" >
                            {{dropFile.name}}
                            <button class="delete is-small"
                                type="button"
                                @click="dropFile = null">
                            </button>
                        </span>
                        <div class="columns">
                            <div class="column">
                                <b-field label="" :type="pr.type" :message="pr.message">
                                    <b-input @input="validateProduct()" autofocus v-model="form.product" placeholder="Product name"></b-input>
                                </b-field>
                            </div>
                        </div>
                        <div class="columns is-mobile">
                                <!-- <div class="column is-2">
                                    
                                </div> -->
                                <div class="column is-half">
                                    <b-field label="" :type="cat.type" :message="cat.message">
                                        <b-button type="is-primary" style="padding:1.5rem 1rem" icon-left="switch" @click="switchCategory"></b-button>
                                        <b-select @input="validateCategory" expanded v-if="categorySelect" v-model="form.category" placeholder="Category">
                                            <option
                                                v-for="cat in getCategories"
                                                :value="cat"
                                                :key="cat">
                                                {{ cat }}
                                            </option>
                                        </b-select>
                                        <b-input v-else v-model="form.category" @input="validateCategory" placeholder="category"></b-input>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field label="" :type="typ.type" :message="typ.message">
                                        <b-button type="is-primary" style="padding:1.5rem 1rem" icon-left="switch" @click="switchType"></b-button>
                                        <b-select expanded v-if="typeSelect" @change="validateType" v-model="form.type" placeholder="Type">
                                            <option
                                                v-for="type in types"
                                                :value="type"
                                                :key="type">
                                                {{ type }}
                                            </option>
                                        </b-select>
                                        <b-input v-else v-model="form.type" @input="validateType" placeholder="Type"></b-input>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns is-mobile">
                                <div class="column is-7 mb-0 pb-0">
                                    <b-field label="" :type="det.type" :message="det.message">
                                        <b-input @input="validateDetails()" v-model="form.details" placeholder="Product details" maxlength="20"></b-input>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field :type="pc.type" :message="pc.message">
                                        <b-input step="0.01" type="number" @input="validatePrice()" v-model="form.price" placeholder="Price"></b-input>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns is-mobile pt-0">
                                <div class="column is-8 mb-0 pb-0">
                                    <!-- <b-field label="" message="">
                                        <b-numberinput 
                                            placeholder="Discount"
                                            controls-position="compact"
                                            step="5"
                                            v-model="form.discount"
                                            aria-minus-label="Decrement by 5"
                                            aria-plus-label="Increment by 5">
                                        </b-numberinput>
                                    </b-field> -->
                                    <b-field label="" class="px-4">
                                        <b-slider v-model="form.discount" :custom-formatter="(val) => + val + '%'" :tooltip="false" indicator></b-slider>
                                    </b-field>
                                    
                                    <span class="px-2" v-if="this.form.discount > 0 && this.form.price > 0"> Discount Price: {{computeDiscounted}} </span>
                                </div>
                                <div class="column ">
                                    <b-numberinput controls-alignment="right" :controls="false" type="number" v-model="form.discount" placeholder="Discount"></b-numberinput>
                                </div>
                            </div>
                            <div class="columns is-mobile">
                                <div class="column">
                                    <b-field label="">
                                        <b-input v-model="form.tags" placeholder="Product tags e.g tag1, tag2, tag3" ></b-input>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns is-mobile mb-0">
                                <div class="column">
                                    <b-field label="">
                                        <b-input style="height:" type="textarea"
                                            minlength="10"
                                            maxlength="100"
                                            v-model="form.description"
                                            placeholder="Description">
                                        </b-input>
                                    </b-field>
                                </div>
                            </div>

                            <b-field class="py-0">
                                <b-button expanded @click="onSubmit" rounded class="is-submit-button" type="is-dark">Submit</b-button>
                            </b-field>
                        
                    </div>
                </div>
                <!-- <div class="px-2 py-1"> -->
                    <!-- <b-button  type="is-primary has-text-weight-medium" class="is-submit-button" rounded expanded>Submit</b-button> -->
                    <!-- <b-button  style="position:absolute;bottom: 10px;width: 100%;margin:auto" type="mx-3 is-primary has-text-weight-medium" class="is-submit-button" rounded expanded>Add to Cart</b-button> -->
                <!-- </div> -->
                
            </div>
        </b-modal>
    </div>
</template>

<script>

    import { mapGetters, mapActions} from 'vuex'
    import MainFooter from '../components/footer'
    import SideNav from '../components/sidenav'
    import ProductTemplate from '../components/ProductTemplate'
    import axios from 'axios'

    export default {
        name: "ProductPage",
        data() {
            return {
                editProduct: false,
                img: '',
                dropFile: null,
                result: [],
                searchString: '',
                isLoading: false,
                loading: false,
                isCardModalActive: false,
                categorySelect: true,
                typeSelect: true,
                types: ['Item',
                        'Pack',
                        'Bags',
                        'Per Weight',
                        'Per Volume'],
                form: {
                    business: '',
                    product: '',
                    image: '',
                    category: null,
                    type: null,
                    details: '',
                    visible: true,
                    price: null,
                    discount: 0,
                    description: '',
                    tags: '',
                    by: ''
                },
                im: {
                    type:'',
                    message:'',
                },
                pr: {
                    type:'',
                    message:'',
                },
                pc: {
                    type:'',
                    message:'',
                },
                cat: {
                    type:'',
                    message:'',
                },
                typ: {
                    type:'',
                    message:'',
                },
                det: {
                    type:'',
                    message:'',
                },
                uploadProgress: 0,
                show: true,
                submitted: false
            }
        },
        components: {
            // MainFooter,
            ProductTemplate,
            SideNav
        },
        watch: {
            submitted() {
                if (this.submitted == true) {
                    //

                }
            },
            searchString() {
                if (this.searchString.length == 0) {
                    this.result = []
                }
            },
            isCardModalActive(){
                if (this.isCardModalActive == false) {
                    this.editProduct = false,
                    this.form = {}
                }
            },
            dropFile() {
                //
                // this.validateImg()
                //
                if (this.dropFile !== null ){
                    this.img = window.URL.createObjectURL(this.dropFile)
                    

                    // const ax = this.axios.create({
                    //     baseURL: 'https://api.cloudinary.com/'
                    // })

                    // //
                    // ax.post('/v1_1/df8hf97qf/image/upload', formData,
                    //         { onUploadProgress: ProgressEvent => {
                    //             let progress = Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100)
                    //             this.uploadProgress = progress
                    //           },
                    //         })
                    //         .then( (res) =>  {
                    //           console.log(res)
                    //           //
                    //           this.form.image = res.data.secure_url
                    //          //
                    //          if (res.data.message == 'Success') {
                    //            this.$buefy.toast.open(`Successfully uploaded`)
                    //            // success
                    //            this.uploadProgress = 0;
                    //         //    this.dropFiles = [];

                    //         //    setTimeout(()=> {
                    //         //       this.refresh()
                    //         //     }, 800)
                    //          }
                    //         }).catch((err)=> {
                    //             console.log(err)
                    //           this.uploadProgress = 0
                    //           this.$buefy.toast.open(`Failed: ${err.response.data}`)
                    //         })
                    
                }

                if (this.dropFile == null) {
                    window.URL.revokeObjectURL(this.img)
                }
            }
        },
        mounted() {
            this.getProducts()

            //
            this.form.business = this.getUser.business
            this.form.by = this.getUser.business
        },
        computed: {
            ...mapGetters('auth', ['getUser']),
            ...mapGetters('products', ['allProducts']),

            getAdminProducts() {
                return this.allProducts.filter( (pro)=> pro.by == 'Admin')
            },
            getUserProducts() {
                return this.allProducts.filter( (pro)=> pro.by !== 'Admin')
            },
            getCategories() {

                const allcategories =  this.allProducts.map(product => product.category)
                const categories  = [...new Set(allcategories)].sort()
                return categories
            },
            computeDiscounted(){
                // return Math.round((this.form.price * ((100 - parseFloat(this.form.discount))/100) * 1000), 2)/1000
                return (this.form.price * ((100 - parseFloat(this.form.discount))/100)).toFixed(2)
            },
            getNewProduct(){
                return this.$store.getters['products/newProduct']
            },
            getUpdateProduct(){
                return this.$store.getters['products/updateProduct']
            }

        },
        methods: {
            validateImg() {
                if (this.dropFile == null){
                    this.im.type = 'is-danger';
                    this.im.message ='Kindly select an image file'
                    return false
                }

                else {
                    this.im.type = 'is-success';
                    this.im.message =''
                    return true
                }
            },
            validateProduct() {
                if (this.form.product.length < 3){
                    this.pr.type = 'is-danger';
                    this.pr.message ='Enter 3-characters or above'
                    return false
                }

                if (this.form.product.length >= 3){
                    this.pr.type = 'is-success';
                    this.pr.message =''
                    return true
                }
            },
            validatePrice() {

                const reg = new RegExp(/^\d*\.?\d*$/)

                if (this.form.price == null) {
                    this.pc.type = 'is-danger';
                    this.pc.message ='Kindly enter price'
                    return false
                }
                
                else if (reg.test(this.form.price)) {

                    // if (this.form.price.length > 0){
                        this.pc.type = 'is-success';
                        this.pc.message =''
                        return true
                    // }
                    // else {
                    //     this.pc.type = 'is-danger';
                    //     this.pc.message ='Kindly enter a valid price'
                    //     return false
                    // }
                } else {
                    if (this.form.price.length <=8){
                        this.pc.type = 'is-danger';
                        this.pc.message ='Kindly enter a number'
                        return false
                    }
                }

                
            },
            validateCategory() {
                if (this.form.category == null || this.form.category.length < 3){
                    this.cat.type = 'is-danger';
                    this.cat.message ='Select above / Enter 3-characters or above'
                    return false
                }

                if (this.form.category.length >= 3){
                    this.cat.type = 'is-success';
                    this.cat.message =''
                    return true
                }
            },
            validateType() {
                if ( this.form.type == null || this.form.type.length < 3){
                    this.typ.type = 'is-danger';
                    this.typ.message ='Select above / Enter 3-characters or above'
                    return false
                }

                if (this.form.type.length >= 3){
                    this.typ.type = 'is-success';
                    this.typ.message =''
                    return true
                }
            },
            validateDetails() {
                if (this.form.details.length < 3){
                    this.det.type = 'is-danger';
                    this.det.message =' Enter 3-characters or above'
                    return false
                }

                if (this.form.details.length >= 3){
                    this.det.type = 'is-success';
                    this.det.message =''
                    return true
                }
            },
            deleteDropFile(index) {
                this.dropFile.splice(index, 1)
            },
            addProduct(){
                this.isCardModalActive = true
            },
            edit(product) {
                this.form.business = product.business
                this.form.product = product.product
                this.form.image = product.image
                this.form.category = product.category
                this.form.type = product.type
                this.form.details = product.details
                this.form.price = product.price
                this.form.discount = product.discount
                this.form.description = product.description
                this.form.by = product.by
                delete this.form.visible
                // this.form = product
                this.form.tags = (product.tags.length > 0) ? product.tags.join(',') : ''
                this.editProduct = true
                this.isCardModalActive =true
            },
            onSubmit() {

                // validate
                if ( !this.validateProduct() || !this.validateCategory() || !this.validateType() || !this.validateDetails() || !this.validatePrice()){
                    return
                }

                this.isLoading = true

                //
                const cloudURL = 'https://api.cloudinary.com/v1_1/df8hf97qf/image/upload'
                // upload to cloudinary first
                if (this.dropFile){
                    //
                    const formData = new FormData()
                    formData.append('upload_preset', 'qwwhqvhe')
                    formData.append('folder', 'products/')
                    // formData.append('public_id', this.dropFile.name)
                    formData.append('file', this.dropFile)

                    fetch(cloudURL, {
                        method: 'POST',
                        body: formData
                    }).then(async response=> {
                        const data = await response.json()

                        if (!response.ok) {
                            const error = (data && data.message) || response.status
                            return Promise.reject(error)
                        }

                        //
                        this.form.image = data.secure_url
                        this.form.tags = this.form.tags.split(',')
                        //
                        this.form.price = parseFloat(this.form.price)
                            
                        await this.$store.dispatch('products/createProduct', this.form).then((res)=>{
                            //
                            if (this.getNewProduct) {
                                //
                                this.$buefy.toast.open(`Successfully added ${this.form.product}`)
                                //
                                this.isCardModalActive = false
                                // clear
                                this.$store.dispatch('products/clearNewUpdate', 'new')

                            } else {
                                this.$buefy.toast.open('Oops, Failed to product')
                            }

                            this.isLoading = false
                        })
                    })

                    
                } else {

                    if (this.editProduct){
                        //
                        this.form.tags = this.form.tags.split(',')
                        //
                        this.form.price = parseFloat(this.form.price)
                        //
                        this.$store.dispatch('products/updateProduct', { product: this.form.product, business: this.form.business, update: this.form }).then((res)=>{
                            //
                            if (this.getUpdateProduct) {
                                //
                                this.$buefy.toast.open(`Successfully updated ${this.form.product}`)
                                //
                                this.isCardModalActive = false
                                // clear
                                this.$store.dispatch('products/clearNewUpdate', 'update')

                            } else {
                                this.$buefy.toast.open('Oops, Failed to product')
                            }

                            this.isLoading = false
                        })

                    }  else {
                        this.$buefy.toast.open('Oops, please select an image')
                    }
                    
                }

                //

            },
            switchCategory() {
                this.categorySelect = this.categorySelect ? false : true
            },
            switchType() {
                this.typeSelect = this.typeSelect ? false : true
            },
            async getProducts() {
                await this.$store.dispatch('products/getProducts', this.getUser.business)
                
            },
            async Search() {
                // console.log('here')
                if (this.searchString.length >= 3) {
                    
                   
                    const result = await this.$store.getters['products/searchProducts'](this.searchString)

                    this.result = result
                    
                }
            },
            load() {
                this.loading = true 
                setTimeout(()=>{
                    this.loading = false
                }, 500)
            },
        }
    }
</script>

<style lang="scss" >
@import '@/assets/scss/custom_variables.scss';

    .tabs li.is-active a {
        border-bottom-color: $primary !important;
        color: $primary !important;
    }

    .minus > button, .plus > button{
        padding: 1.5rem 1rem;
    }

    .textarea:not([rows]) { 

        min-height: 5em !important;
    }
</style>