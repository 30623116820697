<template>
    <div>
        <!-- <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true"></b-loading> -->
        <div v-if="findOrder" class=" p-5">
            
            <feather size="30" @click="goBack" type="arrow-left" class="my-5 mx-2 dark" style="top:0;position:absolute" ></feather>
            <!-- <feather size="30" type="phone" class="mx-5 mx-2 dark" style="right:0;position:absolute" ></feather> -->
            <a class="has-text-primary" :href="`tel:${findOrder.user.countryCode+findOrder.user.tel}`"> <feather size="30" type="phone" class="mx-5 mx-2 dark" style="right:0;position:absolute" ></feather> </a>
            <h4 class="title is-4 ml-5 pl-5"> {{ findOrder.username }} </h4>

            <div class="p-3">
                <b-tag rounded :type="findOrder.confirmed !== null ? 'is-info' : (findOrder.orderDelivered !== null ? 'is-success' : findOrder.cancelled ? 'is-danger' : findOrder.confirmed == null ? 'is-warning' : 'is-light')" class="my-2">{{ findOrder.status }}</b-tag>
                <h4 class="title is-4 mb-2">Order {{ findOrder.orderNo.split('-')[0] }}</h4>
                <p class="is-5 "> {{ findOrder.orderDelivered ? formatAMPM(findOrder.orderDelivered) : formatAMPM(findOrder.createdAt) }}</p>
            </div>
            <div class="card">
                <!-- {{items }} -->
            </div>
            <CartTemplate :isControls="false" :products="findOrder.items"/>
            <!--  -->
            <!-- Total -->
            
            <div v-if="findOrder.items.length > 0" class="card p-3">
                <h4 class="title is-5 p-2 pt-3">Total</h4>
                <div class="px-2">
                    <div v-if="(findOrder.totalActualPrice !== findOrder.totalPrice)" class="columns is-mobile mb-0">
                        <div class="column">
                            <p>Savings</p>
                        </div>
                        <div class="column has-text-right">
                            <p>(GH₵{{ (findOrder.totalActualPrice - findOrder.totalPrice).toFixed(2) }})</p>
                        </div>
                    </div>
                    <div class="columns is-mobile mb-0">
                        <div class="column">
                            <p class="has-text-weight-semibold">Subtotal</p>
                        </div>
                        <div class="column has-text-right">
                            <p class="has-text-weight-semibold">{{ findOrder.data.country.currency }}{{ findOrder.totalPrice.toFixed(2) }}</p>
                        </div>
                    </div>
                    <div class="columns is-mobile mb-0">
                        <div class="column">
                            <p class="">Delivery fee</p>
                        </div>
                        <div class="column has-text-right">
                            <p class="">{{ (findOrder.deliveryFee == 0 ? 'FREE' : findOrder.data.country.currency+findOrder.deliveryFee.toFixed(2)) }}</p>
                        </div>
                    </div>
                    <hr class="my-3 p-0">
                    <div class="columns is-mobile mb-0">
                        <div class="column">
                            <p class="has-text-weight-bold is-5">Total</p>
                        </div>
                        <div class="column has-text-right">
                            <p class="has-text-weight-bold is-5">{{ findOrder.data.country.currency }}{{ (findOrder.deliveryFee == 0 ? findOrder.totalPrice : findOrder.totalPrice + findOrder.deliveryFee).toFixed(2) }}</p>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="card p-3 my-2 " >
                <h4 class="title is-5 p-2 pt-3">Delivery</h4>
                <div style="display:flex;align-content: center;align-items: center">
                    <!-- <feather type="map-pin" class="p-2"></feather>  -->
                    <p icon class=" is-5 is-grey has-text-weight-medium "> {{ findOrder.deliveryAddressName }} </p>
                    <p icon class=" is-5 is-grey has-text-weight-medium "> {{ findOrder.deliveryNote }} </p>
                </div>

                
            </div>
            
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex"
    import CartTemplate from '../components/CartTemplate'

    export default {
        name: "OrderDetailsPage",
        data() {
            return {
                business: '',
                orderNo: '',
                businessData: null,
                isOpen: true,
                isLoading: false
            }
        },
        components: {
            CartTemplate
        },
        computed: {
            ...mapState('cart', { items: state=> state.cart }),
            ...mapGetters('cart', ['totalPrice']),
            ...mapGetters('cart', ['getCartBusiness']),
            ...mapGetters('cart', ['cartCount']),
            getBusiness() {
                return this.$store.getters['vendors/currentBusiness'](this.business)
            },
            findOrder() {
                return this.$store.getters['orders/getOrder'](this.orderNo)
            }
        },
        mounted() {
            this.business = this.$store.getters['cart/getCartBusiness']
            this.orderNo = this.$route.params.orderNo
        },
        methods: {
            goBack() {
                this.$router.back()
            },
            formatAMPM(date) {
                const dt = new Date(date)
                var h = dt.getUTCHours();
                var m = dt.getUTCMinutes();
                var ampm = h >= 12 ? 'pm' : 'am';
                h = h % 12;
                h = h ? h : 12;
                m = m < 10 ? '0'+m : m;
                var strTime = h + ':' + m + ' ' + ampm;
                return dt.toDateString()+', '+strTime;
            },
            emptyCart() {

                this.$buefy.dialog.confirm({
                            title: 'Empty Cart',
                            message: `Are you sure you want to delete all items in you cart.`,
                            confirmText: 'Confirm',
                            type: 'is-danger',
                            // hasIcon: true,
                            onConfirm: () => {
                                this.$store.dispatch('cart/emptyCart')
                                this.$router.push('/index')
                            }
                        })

                
            }
        }
    }
</script>

<style lang="scss" scoped>
    .link:hover, .link:focus {
        background: grey;
        background: #f4f4f4;
        border-radius: 1.2rem;
    }
</style>